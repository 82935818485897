import { completeUserClaims } from '@logto/core-kit';
import { z } from 'zod';
export const samlAttributeMappingKeys = Object.freeze(['id', ...completeUserClaims]);
export const samlAttributeMappingGuard = z
    .object(Object.fromEntries(samlAttributeMappingKeys.map((claim) => [claim, z.string()])))
    .partial();
export var BindingType;
(function (BindingType) {
    BindingType["Post"] = "urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST";
    BindingType["Redirect"] = "urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Redirect";
})(BindingType || (BindingType = {}));
export const samlAcsUrlGuard = z.object({
    binding: z.nativeEnum(BindingType),
    url: z.string().url(),
});
export const samlEncryptionGuard = z
    .object({
    encryptAssertion: z.boolean().optional(),
    encryptThenSign: z.boolean().optional(),
    certificate: z.string().optional(),
})
    .superRefine(({ encryptAssertion, encryptThenSign, certificate }, ctx) => {
    if (encryptAssertion && (encryptThenSign === undefined || certificate === undefined)) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: '`encryptThenSign` and `certificate` are required when `encryptAssertion` is `true`',
        });
        return z.NEVER;
    }
});
export var NameIdFormat;
(function (NameIdFormat) {
    /** Uses unique and persistent identifiers for the user. */
    NameIdFormat["Persistent"] = "urn:oasis:names:tc:SAML:2.0:nameid-format:persistent";
    /** Returns the email address of the user. */
    NameIdFormat["EmailAddress"] = "urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress";
    /** Uses unique and transient identifiers for the user, which can be different for each session. */
    NameIdFormat["Transient"] = "urn:oasis:names:tc:SAML:2.0:nameid-format:transient";
    /** The Identity Provider can determine the format. */
    NameIdFormat["Unspecified"] = "urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified";
})(NameIdFormat || (NameIdFormat = {}));
export const nameIdFormatGuard = z.nativeEnum(NameIdFormat);
